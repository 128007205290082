/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import './Contato.css';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Whatsapp from '../../Media/Contato/Whatsapp.svg';
import Phone from '../../Media/Contato/Phone.svg';
import Email from '../../Media/Contato/Email.svg';
import LocationPin from '../../Media/Contato/LocationPin.svg';
import Clock from '../../Media/Contato/Clock.svg';
import SaniTruck from '../../Media/Contato/SaniTruck.webp';

let aaaa = false;

function ContatoPage() {
  const [count, setCount] = React.useState(0);
  function buildForm() {
    if (aaaa === false) {
      new window.RDStationForms('contato-site-institucional-c9f4c30e9fb7d2d0d3f2', 'UA-258025768-1').createForm();
      aaaa = true;
    }
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCount(1);
    }, 500);
  
    buildForm();
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  return (
    <div className="ContatoPage">
      <Header />
      <div className="ContatoMain">
        <div className="standardContainer bgDecorationContainer contatoFirstContainer">
          <div className="contatoFirstContainerText">
            <h2 className="blueText">ESTAMOS AQUI PARA AJUDAR</h2>
            <p className="biggerText">Entre em contato conosco e vamos atender o mais rápido possível.</p>
            <div className="hideOnMobile nossosCanaisContainer">
              <h3 style={{ marginBottom: '16px' }} className="orangeText contactHeading">Nossos canais</h3>
              <div className="textAndImage">
                <img src={Phone} alt="Telefone" />
                <p>(92) 3612-1212</p>
              </div>
              <div className="textAndImage">
                <img src={Whatsapp} alt="Whatsapp" />
                <p>(92) 98802-3592</p>
              </div>
              <div className="textAndImage">
                <img src={Email} alt="Email" />
                <p style={{ textDecoration: 'underline' }}>comercial@saniteck.com.br</p>
              </div>
            </div>

          </div>
          <div className="imageContainer">
            <img src={SaniTruck} alt="Nossa sede e um de nossos veiculos" />
          </div>
        </div>
        <div className="showOnMobile nossosCanaisContainer">
          <h3 style={{ marginBottom: '16px' }} className="orangeText contactHeading">Nossos canais</h3>
          <div className="textAndImage">
            <img src={Phone} alt="Telefone" />
            <p>(92) 3612-1212</p>
          </div>
          <div className="textAndImage">
            <img src={Whatsapp} alt="Whatsapp" />
            <p>(92) 98802-3592</p>
          </div>
          <div className="textAndImage">
            <img src={Email} alt="Email" />
            <p style={{ textDecoration: 'underline' }}>comercial@saniteck.com.br</p>
          </div>
        </div>

        <div className="standardContainer contatoLastContainer">
          <div className="googleMapsCollum">
            <h2 className="contactHeading orangeText">Onde estamos</h2>
            <div className="iframeContainer">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15935.608452978377!2d-60.0014428!3d-3.120588!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9a61f0c69d449673!2sSaniteck!5e0!3m2!1spt-BR!2sbr!4v1676600950391!5m2!1spt-BR!2sbr" title="Google Maps" />
            </div>

            <div style={{ marginBottom: '16px' }} className="textAndImage">
              <img src={LocationPin} alt="Location Pin" />
              <p>Av. Maués, 1398 - Cachoeirinha, Manaus - AM.</p>
            </div>

            <h4 className="blueText">Horário de atendimento</h4>

            <div className="textAndImage">
              <img src={Clock} alt="Clock" />
              <p>7h30 às 17h30 | segunda à sexta-feira</p>
            </div>

            <div className="textAndImage">
              <img src={Clock} alt="Clock" />
              <p>7h30 às 11h30 | aos sábados</p>
            </div>
            
          </div>

          <div className="contactFormContainer">
            <div role="main" id="contato-site-institucional-c9f4c30e9fb7d2d0d3f2" />
            {/* <legend className="contactHeading orangeText">Envie sua dúvida</legend>
            <p>Deixe seus dados e nosso time vai ajudar você!</p>

            <div className="contactForm">
              <label className="inputLabel" htmlFor="nameInput">
                <p>Nome</p>
                <div className="inputAndIcon">
                  <img src={NameInputIcon} alt="nameInput" />
                  <input id="nameInput" />
                </div>
              </label>

              <label className="inputLabel" htmlFor="email">
                <p>Email</p>
                <div className="inputAndIcon">
                  <img src={EmailInputIcon} alt="email" />
                  <input id="email" />
                </div>
              </label>

              <label className="inputLabel" htmlFor="celular">
                <p>Celular</p>
                <div className="inputAndIcon">
                  <img src={PhoneInputIcon} alt="celular" />
                  <input id="celular" />
                </div>
              </label>

              <label className="inputLabel" htmlFor="assunto">
                <p>Assunto</p>
                <textarea maxLength={144} id="assunto" />
                <p style={{ fontSize: '10px' }}>⚠️ Máximo de 144 caracteres</p>
              </label>

              <label id="checkboxInputLabel" className="inputLabel" htmlFor="nameInput">
                <input type="checkbox" id="nameInput" />  
                <p>Autorizo o acesso a meus dados e concordo com a <strong>Política de Privacidade</strong> da Saniteck.</p> 
              </label>

              <button className="orangeBtn" type="button">
                Enviar Para Atendimento
              </button>
            </div> */}
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default ContatoPage;
