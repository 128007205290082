/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React from 'react';
import './Servicos.css';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import solutionLogo from '../../Media/Servicos/solutionLogo.svg';
import zap from '../../Media/Header/Phone.svg';
import image1 from '../../Media/Servicos/image1.webp';
import image2 from '../../Media/Servicos/image2.webp';
import image3 from '../../Media/Servicos/image3.webp';
import image4 from '../../Media/Servicos/image4.webp';
import Clock from '../../Media/Servicos/Clock.svg';
import Truck from '../../Media/Servicos/Truck.svg';
import PhoneMessage from '../../Media/Servicos/PhoneMessage.svg';
import Message from '../../Media/Servicos/Message.svg';
import goBackArrow from '../../Media/Servicos/goBackArrow.svg';
import goForwardArrow from '../../Media/Servicos/goForwardArrow.svg';
import whatsappContact from '../../Resources';
import agua from '../../Media/agua.svg';
import pragas from '../../Media/pragas.svg';
import efluentes from '../../Media/efluentes.svg';
import fossa from '../../Media/fossa.svg';
import sanitizacao from '../../Media/sanitizacao.svg';
import poco from '../../Media/poco.svg';

function SolucoesPage() {
  const [imageIndex, changeImageIndex] = React.useState(0);
  const imagesArray = [image1, image2, image3, image4];

  function handleArrowClick(backArrowClicked) {
    const currentIndex = imageIndex;
    if (currentIndex === 3 && backArrowClicked !== true) {
      return changeImageIndex(0);
    }
    if (backArrowClicked) {
      if (currentIndex === 0) {
        return changeImageIndex(imagesArray.length - 1);
      }
      return changeImageIndex(currentIndex - 1);
    }

    return changeImageIndex(currentIndex + 1);
  }

  return (
    <div className="SolucoesPage">
      <Header />
      <div className="SolucoesMain">
        <div className="standardContainer bgDecorationContainer solucoesFirstContainer">
          <div className="solucoesFirstContainerText">
            <h1 style={{ fontWeight: 800 }} className="pageNameText">ENTENDA NOSSAS SOLUÇÕES</h1>
            <p>Todos os nossos serviços são essenciais para garantir a saúde de quem reside nas casas, empresas e instituições públicas e por isso precisam ser realizados periodicamente.</p>
            <h2 className="orangeText">Agende uma vistoria e receba seu orçamento</h2>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="imageContainer">
            <img src={imagesArray[imageIndex]} alt="" />
            <div className="arrowImageControls">
              <img src={goBackArrow} onClick={() => handleArrowClick(true)} alt="" />
              <img src={goForwardArrow} onClick={() => handleArrowClick()} alt="" />
            </div>

            <div className="currentImageHandler">
              <div onClick={() => changeImageIndex(0)} className={imageIndex === 0 ? 'imageSelector currentImage' : 'imageSelector'} />
              <div onClick={() => changeImageIndex(1)} className={imageIndex === 1 ? 'imageSelector currentImage' : 'imageSelector'} />
              <div onClick={() => changeImageIndex(2)} className={imageIndex === 2 ? 'imageSelector currentImage' : 'imageSelector'} />
              <div onClick={() => changeImageIndex(3)} className={imageIndex === 3 ? 'imageSelector currentImage' : 'imageSelector'} />
            </div>
          </div>
        </div>

        <div className="solucoesSecondContainer">
          <div className="standardContainer twoSolutionsContainer">
            <div>
              <div className="solutionHeaderAndImageContainer" id="agua">
                <img src={agua} alt="" />
                <img className="hideOnMobile" src={solutionLogo} alt="" />
              </div>
              <div className="solutionHeaderAndImageContainer textAndImage">
                <h2 style={{ fontWeight: 600 }} className="biggerText blueText">Higienização de Reservatórios de Água</h2>
              </div>
              <ul>
                <li>Limpeza seguida de desinfecção.</li>
                <li>Ação feita com água e detergente.</li>
                <li>Realiza a remoção da sujeira visual.</li>
              </ul>
              <button onClick={() => whatsappContact('reservatorios')} type="button" className="buttonWithArrow orangeBtn">
                <p>Quero saber mais</p>
              </button>
            </div>

            <div>
              <div className="solutionHeaderAndImageContainer">
                <img src={poco} alt="" />
                <img className="hideOnMobile" src={solutionLogo} alt="" id="poco" />
              </div>
              <div className="solutionHeaderAndImageContainer textAndImage">
                <h2 style={{ fontWeight: 600 }} className="biggerText blueText">Manutenção de Poço</h2>
              </div>
              <ul>
                <li>Limpeza e higienização do poço.</li>
                <li>Realização de outorga.</li>
                <li>Manutenção de forma geral.</li>
              </ul>
              <button onClick={() => whatsappContact('poco')} type="button" className="buttonWithArrow orangeBtn">
                <p>Quero saber mais</p>
              </button>
            </div>
          </div>

          <div className="standardContainer twoSolutionsContainer">
            <div>
              <div className="solutionHeaderAndImageContainer">
                <img src={pragas} alt="" />
                <img className="hideOnMobile" src={solutionLogo} alt="" id="pragas" />
              </div>
              <div className="solutionHeaderAndImageContainer textAndImage">
                <h2 style={{ fontWeight: 600 }} className="biggerText blueText">Controle de Pragas Urbanas</h2>
              </div>
              <ul>
                <li>Ações preventivas e corretivas que inibem a proliferação de pragas.</li>
                <li>Impedem que encontrem abrigo, alimentos e tenham um ambiente propício para reprodução.</li>
                <li>Alvos: barata; rato; mosquito; mosca; cupim; carrapato; pulga; piolho; traça; percevejo de Cama; pombo; morcego; escorpião.</li>
              </ul>
              <button onClick={() => whatsappContact('controle')} type="button" className="buttonWithArrow orangeBtn">
                <p>Quero saber mais</p>
              </button>
            </div>

            <div>
              <div className="solutionHeaderAndImageContainer">
                <img src={sanitizacao} alt="" />
                <img className="hideOnMobile" src={solutionLogo} alt="" id="sanitizacao" />
              </div>
              <div className="solutionHeaderAndImageContainer textAndImage">
                <h2 style={{ fontWeight: 600 }} className="biggerText blueText">Sanitização</h2>
              </div>
              <ul>
                <li>Elimina 98% dos organismos causadores de doenças.</li>
                <li>Contém função desinfetante para matar os microorganismos invisíveis.</li>
                <li>É produzida uma película protetora que diminui a proliferação de microrganismos.</li>
              </ul>
              <button onClick={() => whatsappContact('sanitizacao')} type="button" className="buttonWithArrow orangeBtn">
                <p>Quero saber mais</p>
              </button>
            </div>
          </div>

          <div className="standardContainer twoSolutionsContainer">
            <div>
              <div className="solutionHeaderAndImageContainer">
                <img src={fossa} alt="" />
                <img className="hideOnMobile" src={solutionLogo} alt="" id="fossa" />
              </div>
              <div className="solutionHeaderAndImageContainer textAndImage">
                <h2 style={{ fontWeight: 600 }} className="biggerText blueText">Esgotamento de Fossa, Gordura e Desentupimentos em Geral</h2>
              </div>
              <ul>
                <li>Tratamento de efluentes domésticos (acumulados em fossa séptica, caixa de gordura) antes de serem descartados.</li>
                <li>Limpeza periódica das caixas de gordura garante o livre fluxo de funcionamento da rede coletora de esgotos domésticos.</li>
              </ul>
              <button onClick={() => whatsappContact('esgotamento')} type="button" className="buttonWithArrow orangeBtn">
                <p>Quero saber mais</p>
              </button>
            </div>

            <div>
              <div className="solutionHeaderAndImageContainer">
                <img src={efluentes} alt="" />
                <img className="hideOnMobile" src={solutionLogo} alt="" id="efluentes" />
              </div>
              <div className="solutionHeaderAndImageContainer textAndImage">
                <h2 style={{ fontWeight: 600 }} className="biggerText blueText">Manutenção de Estação de Tratamento de Efluentes (ETE)</h2>
              </div>
              <ul>
                <li>Inspeção geral, com medições do nível de PH, OD (oxigênio dissolvido), sólidos sedimentáveis, cloro residual e temperatura.</li>
                <li>Verificação de painel elétrico, sensores, bombas submersíveis, sistema de aeração, avaliação do gradeamento e caixa de gordura.</li>
              </ul>
              <button onClick={() => whatsappContact('manutencao')} type="button" className="buttonWithArrow orangeBtn">
                <p>Quero saber mais</p>
              </button>
            </div>
          </div>

        </div>

        <h2 className="headingText blueText">Por que escolher a Saniteck?</h2>
        <div className="standardContainer solucoesThirdContainer">
          <div>
            <img src={Message} alt="" />
            <p>Rapidez no atendimento e agendamento pelo telefone.</p>
          </div>

          <div>
            <img src={Truck} alt="" />
            <p>Atendemos residências, condomínios, empresas, comércios, indústrias, e órgãos públicos.</p>
          </div>

          <div>
            <img src={PhoneMessage} alt="" />
            <p>Suporte técnico durante todo o contrato.</p>
          </div>

          <div>
            <img src={Clock} alt="" />
            <p>Disponibilidade de horário para realizar o serviço de acordo com a necessidade do cliente.</p>
          </div>
        </div>

        <div className="standardContainer solucoesLastContainer">
          <div>
            <h3 style={{ fontWeight: 600 }} className="biggerText blueText">Experiência e credibilidade com mais de 42 anos no mercado</h3>
            <ul>
              <li>Orçamento sem custo e com inspeção no local.</li>
              <li>Profissionais qualificados e equipe técnica amparada por supervisor, geólogo, biólogo, químico, engenheiro ambiental e engenheiro sanitarista.</li>
              <li>Equipamentos modernos e vasta frota de veículos.</li>
            </ul>
          </div>

          <div>
            <h3 style={{ fontWeight: 600 }} className="biggerText blueText">Licenciamento em todos os órgãos ambientais</h3>
            <ul>
              <li>Produtos químicos aprovados pela DVISA e ANVISA.</li>
              <li>Emissão de laudos e relatórios em todos os serviços prestados.</li>
              <li>Respeito ao meio ambiente.</li>
            </ul>
          </div>
        </div>
        <div className="orgaosContainer" />
        
      </div>
      <Footer />
    </div>
  );
}

export default SolucoesPage;
