function openModal() {
  const currentPage = (window.location.pathname.split('/'))[1];
  if (currentPage === 'contato') {
    const form = document.getElementById('contato-site-institucional-c9f4c30e9fb7d2d0d3f2');
    console.log(form);
    return form.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  return localStorage.setItem('isTheSaniteckWebsiteModalOpen', 'true');
}

export default openModal;
