/* eslint-disable object-curly-newline */
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ContatoPage from './Pages/ContatoPage/Contato';
import HomePage from './Pages/HomePage/Home';
import SobrePage from './Pages/Sobre/Sobre';
import CompliancePage from './Pages/Compliance/Compliance';
import PrivacidadePage from './Pages/Privacidade/Privacidade';
import CookiesPage from './Pages/Cookies/Cookies';
import ServicoesPage from './Pages/Servicos/Servicos';
import ModalComponent from './Components/ModalComponent';
import AppContext from './Context/AppContext';

function App() {
  const [openModal, setModalOpen] = React.useState(false);
  const contextValue = React.useMemo(() => ({ openModal, setModalOpen }), []);

  return (
    <div>
      <BrowserRouter>
        <AppContext.Provider value={contextValue}>
          <ModalComponent />
          <Routes>
            <Route exact path="quemSomos" element={<SobrePage />} />
            <Route exact path="contato" element={<ContatoPage />} />
            <Route exact path="servicos" element={<ServicoesPage />} />
            <Route exact path="*" element={<HomePage />} />
            <Route exact path="compliance" element={<CompliancePage />} />
            <Route exact path="privacidade" element={<PrivacidadePage />} />
            <Route exact path="cookies" element={<CookiesPage />} />
          </Routes>
        </AppContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
