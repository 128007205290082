import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import './Cookies.css';

function CookiesPage() {
  return (
    <div>
      <Header />
      <div className="ComplianceTextPage">
        <div className="textPageStart">
          <h1 className="pageNameText">Política de Cookies</h1>
          <p className="biggerText" style={{ fontWeight: 500 }}>
            Usamos os cookies necessários para fazer o site funcionar da melhor forma possível e sempre aprimorar os nossos serviços.
            <br />
            <br />
            Alguns cookies são classificados como necessários e permitem a funcionalidade central, como segurança, gerenciamento de rede e acessibilidade. Esses cookies podem ser coletados e armazenados assim que você inicia sua navegação ou quando usa algum recurso que os requer.
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Cookies Primários</h2>
          <p className="smallerText">
            Alguns cookies serão colocados em seu dispositivo diretamente pelo nosso site - são conhecidos como cookies primários. Eles são essenciais para você navegar no site e usar seus recursos.
            <br />
            <br />
            <span className="orangeText">
              I. Temporários
            </span>
            <br />
            Nós utilizamos cookies de sessão. Eles são temporários e expiram quando você fecha o navegador ou quando a sessão termina.
            <br />
            <strong>
              Finalidade
            </strong>
            : Estabelecer controle de idioma e segurança ao tempo da sessão.
            <br />
            <br />
            <span className="orangeText">
              II. Persistentes
            </span>
            <br />
            Utilizamos também, cookies persistentes que permanecem em seu disco rígido até que você os apague ou seu navegador o faça, dependendo da data de expiração do cookie. Todos os cookies persistentes têm uma data de expiração gravada em seu código, mas sua duração pode variar.
            <br />
            <strong>
              Finalidade
            </strong>
            : Coletam e armazenam a ciência sobre o uso de cookies no site.
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Cookies de Terceiros</h2>
          <p className="smallerText">
            Outros cookies são colocados no seu dispositivo não pelo site que você está visitando, mas por terceiros, como, por exemplo, os sistemas analíticos.        
            <br />
            <br />
            <span className="orangeText">
              I. Temporários
            </span>
            <br />
            Nós utilizamos cookies de sessão. Eles são Temporários e expiram quando você fecha o navegador ou quando a sessão termina.
            <br />
            <strong>
              Finalidade
            </strong>
            : Coletam informações sobre como você usa o site, como as páginas que você visitou e os links em que clicou. Nenhuma dessas informações pode ser usada para identificá-lo. Seu único objetivo é possibilitar análises e melhorar as funções do site.
            <br />
            <br />
            <span className="orangeText">
              II. Persistentes
            </span>
            <br />
            Utilizamos também cookies persistentes que permanecem em seu disco rígido até que você os apague ou seu navegador o faça, dependendo da data de expiração do cookie. Todos os cookies persistentes têm uma data de expiração gravada em seu código, mas sua duração pode variar. 
            <br />
            <strong>
              Finalidade
            </strong>
            : Coletam informações sobre como você usa o site, como as páginas que você visitou e os links em que clicou. Nenhuma dessas informações pode ser usada para identificá-lo. Seu único objetivo é possibilitar análise e melhorar as funções do site.
          </p>
          <p style={{ fontWeight: 600 }} className="smallerText blueText">
            Você pode desabilitá-los alterando as configurações do seu navegador, mas saiba que isso pode afetar o funcionamento do site.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CookiesPage;
