/* eslint-disable no-useless-escape */
/* eslint-disable no-alert */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import './Compliance.css';
import Compliance from '../../Media/Compliance/Compliance.webp';
import documentIcon from '../../Media/Compliance/documentIcon.svg';
import download from '../../Media/Compliance/download.svg';
import codigoDeEtica from '../../Media/Compliance/codigoDeEtica.pdf';
import codigoDeEticaParaTerceiros from '../../Media/Compliance/codigoDeEticaParaTerceiros.pdf';
import npiAntiCorrupcao from '../../Media/Compliance/npiAntiCorrupcao.pdf';
import npiPoliticaDireitosHumanos from '../../Media/Compliance/npiPoliticaDireitosHumanos.pdf';
import EmailInputIcon from '../../Media/Contato/EmailInputIcon.svg';
import NameInputIcon from '../../Media/Contato/NameInputIcon.svg';
import PhoneInputIcon from '../../Media/Contato/PhoneInputIcon.svg';
import formHelp from '../../Media/Compliance/formHelp.svg';

function CompliancePage() {
  const [formOpen, setFormOpen] = React.useState(false);
  const [Nome, setNome] = React.useState('');
  const [Cargo, setCargo] = React.useState('');
  const [Email, setEmail] = React.useState('');
  const [Celular, setCelular] = React.useState('');
  const [depoimento, setDepoimento] = React.useState('');
  const [agreement, setAgreement] = React.useState(false);

  const [helperHasBeenClicked, setHasBeenHelperClicked] = React.useState(false);

  function setHelperClicked(param) {
    window.scrollTo(0, 0);
    setHasBeenHelperClicked(param);
  }

  function report() {
    if (depoimento === '') {
      window.alert('Preencha o seu depoimento para reportar a ocorrência!');
      return;
    }
    if (agreement === false) {
      window.alert('Concorde com as condições para reportar a ocorrência!');
      return;
    }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(Email) === false) {
      window.alert('Preencha o campo de email para reportar a ocorrência!');
      return;
    }

    const emailJs = window.emailjs;
    emailJs.init('g3cwLEMwrz0Czw7Es');
    const userData = {
      fullName: Nome,
      email: Email,
      cargo: Cargo,
      phone: Celular,
      depoimento,
    };

    emailJs.send('service_0f6nuek', 'template_d2v6zo9', userData);
    window.location.reload();
  }

  const complianceMainContent = (
    <div>
      <div className="complianceSecondContainer">
        <h2 className="orangeText headingText" style={{ marginBottom: '54px' }}>Documentações</h2>
        <div className="documentsContainer">
          <div>
            <h4 style={{ fontSize: '24px', margin: 0 }} className="blueText">Código de Ética e Conduta</h4>
            <div className="documentDownloadContainer">
              <div className="textAndImage">
                <img style={{ height: '100%', width: 'auto' }} src={documentIcon} alt="" />
                <p className="blueText smallerText">Arquivo em PDF</p>
              </div>
              <a id="downloadLink" href={codigoDeEtica} download="Codigo De Ética e Conduta SANITECK">
                <button type="button" className="buttonWithArrow orangeBtn downloadBtn">
                  <img src={download} alt="" />
                  <p>Baixar</p>
                </button>
              </a>
            </div>
            <p className="greyText">
              Neste documento, constam as principais atitudes e atos que são esperados dos nossos colaboradores e dos demais públicos com quem nos relacionamos. Ele reforça conceitos e traz direcionamentos para observarmos questões jurídicas relevantes.
            </p>
          </div>

          <div>
            <h4 style={{ fontSize: '24px', margin: 0 }} className="blueText">Código de Ética e Conduta para Terceiros</h4>
            <div className="documentDownloadContainer">
              <div className="textAndImage">
                <img style={{ height: '100%', width: 'auto' }} src={documentIcon} alt="" />
                <p className="blueText smallerText">Arquivo em PDF</p>
              </div>
              <a id="downloadLink" href={codigoDeEticaParaTerceiros} download="Codigo De Ética e Conduta para Terceiros SANITECK">
                <button type="button" className="buttonWithArrow orangeBtn downloadBtn">
                  <img src={download} alt="" />
                  <p>Baixar</p>
                </button>
              </a>
            </div>
            <p className="greyText">
              O Código de Ética e Conduta para Terceiros estabelece Normas e Procedimentos Internos para as situações previstas no relacionamento profissional da Saniteck com os seus terceiros, como seus fornecedores, prestadores de serviços e clientes.
            </p>
          </div>

          <div>
            <h4 style={{ fontSize: '24px', margin: 0 }} className="blueText">NPI Anticorrupção – Terceiros</h4>
            <div className="documentDownloadContainer">
              <div className="textAndImage">
                <img style={{ height: '100%', width: 'auto' }} src={documentIcon} alt="" />
                <p className="blueText smallerText">Arquivo em PDF</p>
              </div>
              <a id="downloadLink" href={npiAntiCorrupcao} download="NPI Anticorrupção - Terceiros SANITECK">
                <button type="button" className="buttonWithArrow orangeBtn downloadBtn">
                  <img src={download} alt="" />
                  <p>Baixar</p>
                </button>
              </a>
            </div>
            <p className="greyText">
              Estabelecer as normas e procedimentos internos no combate à corrupção, em aderência às leis e ao Código de Ética e Conduta da Saniteck, perante agentes públicos e no relacionamento com fornecedores, clientes, colaboradores, cotistas e comunidade. 
            </p>
          </div>

          <div>
            <h4 style={{ fontSize: '24px', margin: 0 }} className="blueText">NPI Política Direitos Humanos</h4>
            <div className="documentDownloadContainer">
              <div className="textAndImage">
                <img style={{ height: '100%', width: 'auto' }} src={documentIcon} alt="" />
                <p className="blueText smallerText">Arquivo em PDF</p>
              </div>
              <a id="downloadLink" href={npiPoliticaDireitosHumanos} download="NPI Política Direitos Humanos SANITECK">
                <button type="button" className="buttonWithArrow orangeBtn downloadBtn">
                  <img src={download} alt="" />
                  <p>Baixar</p>
                </button>
              </a>
            </div>
            <p className="greyText">
              Estas Normas e Procedimentos Internos visam estabelecer normativas para fortalecer a promoção e o respeito aos direitos humanos em todas as atividades de negócio da Saniteck.
            </p>
          </div>
        </div>
      </div>

      <div className="complianceThirdContainer">
        <h2 className="orangeText headingText" style={{ marginBottom: '36px' }}>Canal de ética</h2>
        {
          formOpen === true ? (
            <div className="complianceData">
              <p style={{ textAlign: 'center' }} className="greyText">
                O Canal de Ética foi criado para proporcionar aos nossos funcionários, terceiros, clientes, fornecedores, comunidades e público em geral, um meio de comunicação confidencial e seguro para o registro de suspeitas ou condutas que violem o Código de Ética e Conduta da Saniteck e/ou legislações vigentes.              
              </p>
              <div className="complianceForm">
                <div className="inputLabel">
                  <p>Nome</p>
                  <div className="inputAndIcon">
                    <img src={NameInputIcon} alt="" />
                    <input value={Nome} onChange={(e) => setNome(e.target.value)} />
                  </div>
                </div>

                <div className="inputLabel">
                  <p>Cargo ou Relação com a Saniteck</p>
                  <div className="inputAndIcon">
                    <input value={Cargo} onChange={(e) => setCargo(e.target.value)} />
                  </div>
                </div>

                <div className="inputLabel">
                  <p>Email<span className="orangeText">*</span></p>
                  <div className="inputAndIcon">
                    <img src={EmailInputIcon} alt="" />
                    <input value={Email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                </div>

                <div className="inputLabel">
                  <p>Celular</p>
                  <div className="inputAndIcon">
                    <img src={PhoneInputIcon} alt="" />
                    <input value={Celular} onChange={(e) => setCelular(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="complianceFormHelper">
                <p style={{ fontSize: '12px' }} className="orangeText">
                  *Único item obrigatório
                </p>
                <img id="formHelp" src={formHelp} alt="" />
                <p id="formHelpText">
                  Ao se identificar, o relator se disponibiliza a ser contatado para esclarecimentos de possíveis dúvidas sobre o depoimento feito. <strong>Todos os relatos serão tratados de forma sigilosa e sua identidade estará protegida. </strong>
                </p>
              </div>

              <div id="textAreaInput" className="inputLabel">
                <p>Dê seu depoimento</p>
                <textarea value={depoimento} onChange={(e) => setDepoimento(e.target.value)} maxLength={500} />
                <p style={{ fontSize: '10px' }}>⚠️ Máximo de 500 caracteres</p>
              </div>

              <div className="complianceDisclaimer">
                <h4 className="smallerText">Proteção de Dados</h4>
                <p>
                  Todos os relatos serão armazenados por tempo indeterminado para a realização do processo de apuração e deliberação sobre o fato/caso reportado, em conformidade com as exigências legais e específicas quanto a Lei Geral da Proteção de Dados - LGPD.
                  <br />
                  <br />
                  Eventuais dados pessoais informados serão tratados conforme as legislações da Lei Geral da Proteção de Dados - LGPD no que diz respeito a proteção de dados pessoais, e serão seguidas no processo de captação pela Saniteck no processo de apuração dos relatos aqui registrados. 
                  <br />
                  <br />
                  <strong>
                    Ao clicar em "Concordo" você indica ciência e concordância com o fornecimento de informações que serão única e exclusivamente utilizadas para esta finalidade por pessoas devidamente autorizadas. 
                  </strong>
                </p>

                <div style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '8px', 
                }}
                >
                  <input type="checkbox" onChange={(e) => setAgreement(e.target.checked)} value={agreement} />
                  <p>Concordo com as condições descritas.</p>
                </div>

                <p>
                  Se desejado, posteriormente, poderá exercer seus direitos de acesso, retificação, cancelamento e oposição. Para saber mais sobre os seus direitos, <span className="orangeText" style={{ cursor: 'pointer' }} onClick={() => setHelperClicked(true)}>clique aqui.</span>
                </p>
              </div>

              <button className="orangeBtn" type="button" onClick={report}>
                <p>
                  Reportar Ocorrência
                </p>
              </button>
            </div>
          ) : (
            <div className="complianceData">
              <h4 id="canalDeEtica" className="blueText" style={{ fontSize: '24px' }}>Algo para reportar? Aqui você pode deixar seu relato</h4>
              <p style={{ textAlign: 'center' }} className="greyText">
                O Canal de Ética é um recurso destinado ao registro, de forma anônima ou identificada, de denúncias ou suspeitas de condutas que violem os princípios éticos e de conduta da Saniteck e/ou legislações vigentes. 
              </p>
              <button onClick={() => setFormOpen(true)} className="orangeBtn" type="button">
                Reportar Ocorrência
              </button>
              <div className="complianceDisclaimer">
                <ul className="blueText">
                  <li>
                    As informações aqui registradas serão recebidas pelo Setor de Compliance, que garante o sigilo das informações, a identidade das pessoas envolvidas, o tratamento adequado e livre de conflito de interesses.
                    <br />
                    <br />
                    <strong>
                      Seu relato também pode ser realizado por email: 
                      <a href="mailto:canal.etica@saniteck.com.br" id="anchorNoStyle" className="orangeText"> canal.etica@saniteck.com.br</a>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );

  const complianceSecondContent = (
    <div className="ComplianceTextPage" style={{ paddingTop: 0, backgroundImage: 'none' }}>

      <p onClick={() => setHelperClicked(false)} className="goBackText">
        <ul>
          <li>
            Voltar para o Canal de Ética
          </li>
        </ul>
      </p>
      <h2 className="orangeText headingText">Proteção de Dados</h2>

      <div className="textAndHeadingContainer">
        <h4 className="biggerText blueText">Qual o compromisso da SANITECK com relação a proteção de dados pessoais?</h4>
        <p className="smallerText">A excelência do funcionamento de um Canal de ética preza pela confidencialidade de todas as informações obtidas. Ressaltamos que o objetivo do Canal de ética é apurar possíveis condutas atiéticas e, dessa forma, garantir um ambiente seguro e profissional para o relato do fato/ocorrência, com anonimato e confidencialidade garantidos. Portanto, todas as informações são proitegidas e qualquer dados pessoal fornecido será tratado de acordo com a Lei Geral da Proteção de Dados - LGPD.</p>
      </div>

      <div className="textAndHeadingContainer">
        <h4 className="biggerText blueText">O que são dados pessoais?</h4>
        <p className="smallerText">
          Em conformidade com a Lei Geral da Proteção de Dados - LGPD, consideram-se dados pessoais quaisquer informações relativas a um pessoa individual identificada ou identificável. Por exemplo: um número de identificação (CPF) ou a um ou mais específicos da sua identidade física, fisiológica, psíquica, econômica, cultural ou social. 
          <br />
          <br />
          A finalidade do Canal de Ética não é obter informações pessoais, porém, devido a natureza do relato, isso poderá acontecer. Neste caso, todas as informações serão protegidas e tratadas de acordo com a Lei Geral da Proteção de Dados.
        </p>
      </div>

      <div className="textAndHeadingContainer">
        <h4 className="biggerText blueText">Quais informações devo registrar em meu relato?</h4>
        <p className="smallerText">
          Deve incluir em seu relato todas as informações necessárias para a apuração do caso, descrevendo a situação, quando, como e onde ela ocorreu, e quem são as pessoas envolvidas. Informações sobre testemunhas, quando aplicável, e evidências e/ou provas que possam ser obtidas são de extremas importância e devem ser informadas. 
          <br />
          <br />
          Recomendamos que você não inclua em seu relato quaisquer detalhes de sua vida pessoal ou dos envolvidos, como por exemplo: detalhes sobre estado de saúde, orientação sexual, escolha religiosa, bens materiais pessoais, etc., a não ser que tais informações sejam necessárias para o entendimento e apuração do fato. 
        </p>
      </div>

      <div className="textAndHeadingContainer">
        <h4 className="biggerText blueText">Quem terá acesso ao meu relato e aos meus dados?</h4>
        <p className="smallerText">
          As informações registradas no canal de ética serão recebidas pela SANITECK, assegurando sigilo absoluto e o tratamento adequado de cada situação pela Alta Administração, sem conflitos de interesses.
        </p>
      </div>

      <div className="textAndHeadingContainer">
        <h4 className="biggerText blueText">O que será feito com o meu relato e quando será excluído?</h4>
        <p className="smallerText">
          As informações de relatos serão armazanadas por tempo indeterminado com o objetivo de realizar o processo de apuração e conclusão dos fatos. 
          <br />
          <br />
          Além disso, informações consolidadas serão utilizadas para a geração de estatísticas internas, mas nenhum nome envolvido ou dado pessoal será exposto. 
        </p>
      </div>

      <div className="textAndHeadingContainer">
        <h4 className="biggerText blueText">Qual o meu dever ao utilizar o Canal de Ética?</h4>
        <p className="smallerText">
          Seu dever é utilizar o canal de ética com boa fé e registrar informações verdadeiras, apresentadas com detalhes e evidênciando os fatos, sempre que possível.
        </p>
      </div>

      <div className="textAndHeadingContainer">
        <h4 className="biggerText blueText">Quais são os meus direitos ao fazer um relato?</h4>
        <p className="smallerText">
          <strong>Direito de ser excluído:</strong> se solicitado, você pode solicitar que suas informações pessoais sejam excluídas física e logicamente das bases de dados do Canal de Ética. Caso solicitar a exclusão, acesse o campo "Acompanhar Relato", informe o seu número de protocolo e descreva a situação que deseja excluir. 
          <br />
          <br />
          <strong>Direito a retificação dos dados:</strong> se solicitado, você pode solicitar e indicar correções e/ou complementações sobre os dados pessoais informados. Caso queira modificar um registro que tenha feito, acesse o campo "Acompanhar Relato", informe o seu número de protocolo e descreva quais informações deseja modificar.
          <br />
          <br />
          <strong>Direito a transparência:</strong> você pode solicitar informações sobre o processamento e armazenamento dos seus dados, incluindo: tempo de retenção, dados de contato do responsável pelos dados pessoais na SANITECK, justificativa para manter o dado pessoal armazenado.
        </p>
      </div>

      <p onClick={() => setHelperClicked(false)} className="goBackText">
        <ul>
          <li>
            Voltar para o Canal de Ética
          </li>
        </ul>
      </p>
    </div>
  );

  let complianceContent = complianceMainContent;

  if (helperHasBeenClicked === true) {
    complianceContent = complianceSecondContent;
  }
  return (
    <div>
      <Header />
      <div className="CompliancePage">
        <div className="complianceFirstContainer standardContainer bgDecorationContainer">
          <div className="complianceTextContainer">
            <h1 style={{ fontWeight: 800 }} className="pageNameText">
              SANITECK 
              <br />
              SER E ESTAR <span className="orangeText">COMPLIANCE</span>
            </h1>
            <p style={{ fontWeight: 500 }} className="biggerText">
              Conheça as políticas de qualidade e
              <br />
              tenha acesso às nossas documentações.
            </p>
          </div>
          <div className="imageContainer">
            <img alt="" src={Compliance} />
          </div>
        </div>
        { complianceContent }
      </div>
      <Footer />
    </div>
  );
}

export default CompliancePage;
