/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable object-curly-newline */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import saniteckHomeLogo from '../../Media/Home/saniteckHomeLogo.svg';
import list from '../../Media/Home/list.svg';
import calendar from '../../Media/Home/calendar.svg';
import receipt from '../../Media/Home/receipt.svg';
import Setinha from '../../Media/Icon.svg';
import whatsappContact from '../../Resources';
import anos from '../../Media/Sobre/anos.svg';
import time from '../../Media/Sobre/time.svg';
import atendimento from '../../Media/Sobre/atendimento.svg';
import melhores from '../../Media/Sobre/melhores.svg';
import certificationsImage from '../../Media/Sobre/certificationsImage.svg';
import blog1 from '../../Media/Home/Carrousel/blog1.webp';
import blog2 from '../../Media/Home/Carrousel/blog2.webp';
import blog3 from '../../Media/Home/Carrousel/blog3.webp';
import servicos1 from '../../Media/Home/Carrousel/servicos1.webp';
import servicos2 from '../../Media/Home/Carrousel/servicos2.webp';
import servicos3 from '../../Media/Home/Carrousel/servicos3.webp';
import image1 from '../../Media/Servicos/image1.webp';
import agua from '../../Media/agua.svg';
import pragas from '../../Media/pragas.svg';
import efluentes from '../../Media/efluentes.svg';
import fossa from '../../Media/fossa.svg';
import sanitizacao from '../../Media/sanitizacao.svg';
import poco from '../../Media/poco.svg';

function HomePage() {
  const [isMobile, setIsMobile] = React.useState(false);
  const [mouseOverImage, setMouseOverImage] = React.useState(false);
  const [mouseOverDiv, setMouseOverDiv] = React.useState(false);
  const [currentScroll, setCurrentScroll] = React.useState(0);
  const [taDescendo, setTaDescendo] = React.useState(true);
 
  React.useEffect(() => {
    const { width } = screen;
    if (width < 1025) {
      setIsMobile(true);
    }
    if (mouseOverImage === false && mouseOverDiv === false) {
      const imagens = Array.from(document.getElementsByClassName('mouseOverImage'));
      if (mouseOverImage === false) {
        imagens.forEach((image) => {
          image.style.position = '';
          const decorationDiv = image.parentNode.childNodes[1];
          decorationDiv.style.display = 'none';
        });
      }
    }

    // const scroll = document.querySelector('.carouselContainer');
    // const scrollInterval = setInterval(() => {
    //   if (taDescendo === false) {
    //     scroll.scrollTop = 0;
    //     setCurrentScroll(0);
    //     return;
    //   }
    //   if (taDescendo === true) {
    //     scroll.scrollTop = currentScroll + 5;
    //     setCurrentScroll(scroll.scrollTop);
    //   }
    // }, 50);

    // if (currentScroll === 0) {
    //   setTaDescendo(true);
    // }

    // if (currentScroll >= scroll.scrollHeight / 5) {
    //   setTaDescendo(false);
    // }

    return () => {
      // clearInterval(scrollInterval);
    };
  }, [mouseOverImage, mouseOverDiv, currentScroll]);

  async function mouseEnterImage(image) {
    setTaDescendo('no');
    if (image === null) {
      return;
    }
    setMouseOverImage(true);
    image.style.position = 'absolute';
    image.style.left = '0';
    image.style.top = '0';
    const decorationDiv = image.parentNode.childNodes[1];
    decorationDiv.style.display = 'flex';
  }
  
  function mouseLeaveImage(image) { 
    setTaDescendo(true);
    if (image === null) {
      return;
    }
    setTimeout(() => {
      setMouseOverImage(false);
    }, 500);
  }

  function mouseLeaveDecoration(decorationDiv) {
    if (decorationDiv === null) {
      return;
    }
    setMouseOverDiv(false);
  }

  function mouseEnterDecoration(decorationDiv) {
    setTaDescendo('no');
    if (decorationDiv === null) {
      return;
    }
    setMouseOverDiv(true);
  }

  function handleBlogClick() {
    const blogDiv = document.querySelector('.blogClickDiv');
    blogDiv.style.display = 'flex';
    setTimeout(() => {
      blogDiv.style.display = 'none';
    }, 2500);
  }

  const navigation = useNavigate();
  function navigate(params, elementToFind) {
    navigation(params);
    setTimeout(() => {
      document.getElementById(elementToFind).scrollIntoView({ block: 'center' });
    }, 50);

    console.log('aqui');
  }

  return (
    <div className="HomePage">
      <Header />
      <div className="HomeMain">
        <div className="bgDecorationContainer standardContainer homeFirstContainer">
          <div className="homeFirstContainerText">
            <img alt="" src={saniteckHomeLogo} />
            <h2 className="orangeText pageNameText">Saniteck</h2>
            <h1 className="blueText pageNameText">CONSCIÊNCIA AMBIENTAL <br /> E RESPEITO PELA SUA SAÚDE</h1>
            <button onClick={() => whatsappContact('reservatorios')} type="button" className="buttonWithArrow orangeBtn">
              <p>Quero Saber Mais</p>
            </button>
          </div>
          <div className="carouselContainer imageContainer">
            {/* {
              isMobile === false ? (
                <div id="wholeCarousel" style={{ display: 'flex', flexDirection: 'row', gap: '44px', position: 'relative', justifyContent: 'center' }}>
                  <div className="carouselImages">
                    <div className="imageContainerHome">
                      <img className="mouseOverImage carouselImage" onMouseMove={(t) => mouseEnterImage(t.target)} onMouseLeave={(t) => mouseLeaveImage(t.target)} src={servicos1} id="servicos" alt="" />
                      <div className="decorationDiv" onMouseLeave={(t) => mouseLeaveDecoration(t.target)} onMouseMove={(t) => mouseEnterDecoration(t.target)} style={{ display: 'none' }}>
                        <p>Conheça <span onClick={() => navigate('/servicos')} className="orangeText" style={{ textDecoration: 'underline', cursor: 'pointer' }}>nossos serviços</span> com seus diferenciais e <br /> atendimento <span className="orangeText">personalizado.</span></p>
                      </div>
                    </div>
                    <div className="imageContainerHome">
                      <img className="mouseOverImage carouselImage" onMouseMove={(t) => mouseEnterImage(t.target)} onMouseLeave={(t) => mouseLeaveImage(t.target)} src={servicos2} id="servicos" alt="" />
                      <div className="decorationDiv" onMouseLeave={(t) => mouseLeaveDecoration(t.target)} onMouseMove={(t) => mouseEnterDecoration(t.target)} style={{ display: 'none' }}>
                        <p>Conheça <span onClick={() => navigate('/servicos')} className="orangeText" style={{ textDecoration: 'underline', cursor: 'pointer' }}>nossos serviços</span> com seus diferenciais e <br /> atendimento <span className="orangeText">personalizado.</span></p>
                      </div>
                    </div>
                    <div className="imageContainerHome">
                      <img className="mouseOverImage carouselImage" onMouseMove={(t) => mouseEnterImage(t.target)} onMouseLeave={(t) => mouseLeaveImage(t.target)} src={servicos3} id="servicos" alt="" />
                      <div className="decorationDiv" onMouseLeave={(t) => mouseLeaveDecoration(t.target)} onMouseMove={(t) => mouseEnterDecoration(t.target)} style={{ display: 'none' }}>
                        <p>Conheça <span onClick={() => navigate('/servicos')} className="orangeText" style={{ textDecoration: 'underline', cursor: 'pointer' }}>nossos serviços</span> com seus diferenciais e <br /> atendimento <span className="orangeText">personalizado.</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="carouselImages">
                    <div style={{ height: '5px', width: '1px' }} />
                    <div className="imageContainerHome">
                      <img className="mouseOverImage carouselImage" onMouseMove={(t) => mouseEnterImage(t.target)} onMouseLeave={(t) => mouseLeaveImage(t.target)} src={blog1} id="blog" alt="" />
                      <div className="decorationDiv" onMouseLeave={(t) => mouseLeaveDecoration(t.target)} onMouseMove={(t) => mouseEnterDecoration(t.target)} style={{ display: 'none' }}>
                        <p>Visite <span className="orangeText" onClick={handleBlogClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>nosso blog</span> e tire todas as dúvidas sobre <br /> saneamento, métodos de limpeza e CIP</p>
                      </div>
                    </div>
                    <div className="imageContainerHome">
                      <img className="mouseOverImage carouselImage" onMouseMove={(t) => mouseEnterImage(t.target)} onMouseLeave={(t) => mouseLeaveImage(t.target)} src={blog2} id="blog" alt="" />
                      <div className="decorationDiv" onMouseLeave={(t) => mouseLeaveDecoration(t.target)} onMouseMove={(t) => mouseEnterDecoration(t.target)} style={{ display: 'none' }}>
                        <p>Visite <span className="orangeText" onClick={handleBlogClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>nosso blog</span> e tire todas as dúvidas sobre <br /> saneamento, métodos de limpeza e CIP</p>
                      </div>
                    </div>
                    <div className="imageContainerHome">
                      <img className="mouseOverImage carouselImage" onMouseMove={(t) => mouseEnterImage(t.target)} onMouseLeave={(t) => mouseLeaveImage(t.target)} src={blog3} id="blog" alt="" />
                      <div className="decorationDiv" onMouseLeave={(t) => mouseLeaveDecoration(t.target)} onMouseMove={(t) => mouseEnterDecoration(t.target)} style={{ display: 'none' }}>
                        <p>Visite <span className="orangeText" onClick={handleBlogClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>nosso blog</span> e tire todas as dúvidas sobre <br /> saneamento, métodos de limpeza e CIP</p>
                      </div>
                    </div>
                  </div>
                  <div className="blogClickDiv">
                    Blog disponível em breve!
                  </div>
                </div>
              ) : (
                <div className="carouselImages">
                  <div className="imageContainerHome">
                    <img className="mouseOverImage carouselImage" src={servicos1} id="servicos" alt="" />
                    <div />
                  </div>
                  <div className="imageContainerHome">
                    <img className="mouseOverImage carouselImage" src={servicos2} id="servicos" alt="" />
                    <div />
                  </div>
                  <div className="imageContainerHome">
                    <img className="mouseOverImage carouselImage" src={servicos3} id="servicos" alt="" />
                    <div />
                  </div>
                  <div className="imageContainerHome">
                    <img className="mouseOverImage carouselImage" src={blog1} id="blog" alt="" />
                    <div />
                  </div>
                  <div className="imageContainerHome">
                    <img className="mouseOverImage carouselImage" src={blog2} id="blog" alt="" />
                    <div />
                  </div>
                  <div className="imageContainerHome">
                    <img className="mouseOverImage carouselImage" src={blog3} id="blog" alt="" />
                    <div />
                  </div>
                </div>
              )
            } */}

            <img src={image1} alt="" />
          </div>
        </div>
        <h3 className="headingText blueText">Como nos contratar?</h3>
        <div className="standardContainer homeSecondContainer">
          <div>
            <img alt="" src={list} />
            <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Escolha um de nossos serviços</h3>
            <p className="smallerText">Na página <span onClick={() => navigate('/servicos')} className="orangeText" style={{ textDecoration: 'underline', cursor: 'pointer' }}>Serviços</span> você encontra mais detalhes sobre cada um.</p>
          </div>
          <div>
            <img alt="" src={calendar} />
            <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Agende sua vistoria</h3>
            <p className="smallerText"><span onClick={() => whatsappContact('header')} className="orangeText" style={{ textDecoration: 'underline', cursor: 'pointer' }}>Entre em contato</span> com nossos atendentes e agende sua vistoria.</p>
          </div>
          <div>
            <img alt="" src={receipt} />
            <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Orçamento personalizado</h3>
            <p className="smallerText">Após a inspeção local que acontece na vistoria, você recebe os valores.</p>
          </div>
        </div>

        <h3 style={{ marginBottom: '40px' }} className="headingText blueText">Conheça a Saniteck</h3>

        <div className="homeVideoContainer">
          <iframe title="Um pouquinho mais sobre nós :)" src="https://player.vimeo.com/video/803654429?h=855eabad1e" />
        </div>

        <h3 className="headingText blueText">Nossos serviços</h3>
        <div className="standardContainer homeThirdContainer">
          <div className="threeServicosRow">
            <div>
              <img src={agua} alt="" />
              <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Higienização de Reservatórios de Água</h3>
              <div className="twoButtonsDiv">
                <button onClick={() => navigate('/servicos', 'agua')} type="button" className="buttonWithArrow weirdBtn">
                  <p>Saber mais</p>
                  <img src={Setinha} alt="" />
                </button>
              </div>
            </div>
            <div>
              <img src={poco} alt="" />
              <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Manutenção de Poço</h3>
              <div className="twoButtonsDiv">
                <button onClick={() => navigate('/servicos', 'poco')} type="button" className="buttonWithArrow weirdBtn">
                  <p>Saber mais</p>
                  <img src={Setinha} alt="" />
                </button>
              </div>
            </div>
            <div>
              <img src={pragas} alt="" />
              <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Controle de Pragas Urbanas</h3>
              <div className="twoButtonsDiv">
                <button onClick={() => navigate('/servicos', 'pragas')} type="button" className="buttonWithArrow weirdBtn">
                  <p>Saber mais</p>
                  <img src={Setinha} alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="threeServicosRow">
            <div>
              <img src={efluentes} alt="" />
              <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Manutenção de Estação de Tratamento de Efluentes</h3>
              <div className="twoButtonsDiv">
                <button onClick={() => navigate('/servicos', 'efluentes')} type="button" className="buttonWithArrow weirdBtn">
                  <p>Saber mais</p>
                  <img src={Setinha} alt="" />
                </button>
              </div>
            </div>
            <div>
              <img src={sanitizacao} alt="" />
              <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Sanitização</h3>
              <div className="twoButtonsDiv">
                <button onClick={() => navigate('/servicos', 'sanitizacao')} type="button" className="buttonWithArrow weirdBtn">
                  <p>Saber mais</p>
                  <img src={Setinha} alt="" />
                </button>
              </div>
            </div>
            <div>
              <img src={fossa} alt="" />
              <h3 style={{ fontWeight: 600 }} className="blueText biggerText">Esgotamento de Fossa, Gordura e Desentupimentos em Geral</h3>
              <div className="twoButtonsDiv">
                <button onClick={() => navigate('/servicos', 'fossa')} type="button" className="buttonWithArrow weirdBtn">
                  <p>Saber mais</p>
                  <img src={Setinha} alt="" />
                </button>
              </div>
            </div>
          </div>

        </div>

        <h3 className="headingText blueText">Garantia de cumprir com nosso dever</h3>

        <div className="standardContainer aboutThirdContainer">
          <div className="dutiesContainer">
            <div style={{ marginBottom: '60px' }} className="textAndImage">
              <img src={anos} alt="" />
              <p>Mais de 42 anos de atuação no mercado.</p>
            </div>

            <div className="textAndImage">
              <img src={time} alt="" />
              <p>Time técnico mais capacitado da região.</p>
            </div>
            <p style={{ marginBottom: '44px' }}>Composto por geólogo, biólogo, químico, engenheiro ambiental e engenheiro sanitarista.</p>

            <div className="textAndImage">
              <img src={atendimento} alt="" />
              <p>Rápido atendimento e alta disponibilidade.</p>
            </div>
            <p>Disponibilidade de horário para atender o cliente de acordo com sua necessidade.</p>
          </div>

          <div className="theBestsContainer">
            <div className="textAndImage">
              <img src={melhores} alt="" />
              <p>Estamos com os melhores</p>
            </div>
            <div className="certificationsContainer imageContainer">
              <img alt="Nossos Certificados" src={certificationsImage} />
            </div>
          </div>
        </div>
        <h3 style={{ textAlign: 'center', margin: '0px 60px', fontWeight: 600 }} className="headingText blueText">Licenciamento em todos os orgãos ambientais</h3>
        <div className="orgaosContainer" />
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
