import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router';
import SaniteckLogo from '../Media/SaniteckLogo.svg';
import Menu from '../Media/Header/Menu.svg';
import Phone from '../Media/Header/Phone.svg';
import openModal from '../Resources';

function Header() {
  const [navState, setNavState] = React.useState('closed');
  const [pages] = React.useState({
    home: '',
    servicos: '',
    Blog: '',
    quemSomos: '',
    contato: '',
  });

  const [desktopPages] = React.useState({
    home: '',
    servicos: '',
    Blog: '',
    quemSomos: '',
    contato: '',
  });

  const navigate = useNavigate();
  function handleNavClick() {
    if (navState === 'closed') {
      setNavState('open');
    } else {
      setNavState('closed');
    }
  }

  function findPageAndSetId() {
    const { pathname } = window.location;
      
    const currentPage = (pathname.split('/'))[1];
    if (currentPage === '') {
      pages.home = 'currentPage';
      desktopPages.home = 'currentPageDesktop';
      return;
    }
    pages[currentPage] = 'currentPage';
    desktopPages[currentPage] = 'currentPageDesktop';
  }

  // React.useState(() => {
  //   findPageAndSetId();
  // }, []);

  findPageAndSetId();

  function handleClick(clickedElement) {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    switch (clickedElement) {
      case 'servicos':
        navigate('/servicos');
        break;
      case 'home':
        navigate('/');
        break;
      case 'Blog':
        window.open('https://blog.saniteck.com.br/', '_self');
        break;
      case 'sobre':
        navigate('/quemSomos');
        break;
      case 'contato':
        navigate('/contato');
        break;
      case 'compliance':
        navigate('/compliance');
        break;
      default:
        break;
    }
  }

  return (
    <div className="HeaderComponent">

      <div className="MobileHeader">
        <div className="mobileHeaderStartingItens">
          <div onClick={handleNavClick} className="menuNavIcon">
            <img alt="Navigation Bar" src={Menu} />
          </div>

          <div style={{ justifySelf: 'center' }} onClick={() => handleClick('home')} className="pamHeaderLogo">
            <img alt="Navigation Bar" src={SaniteckLogo} />
          </div>

          <button style={{ width: 0, overflow: 'hidden', backgroundColor: 'transparent' }} className="smallBtn" type="button">
            <img style={{ display: 'none' }} alt="Contato" src={Phone} />
          </button>
        </div>
      </div>
      <div style={navState === 'closed' ? { width: '92%', height: 0, padding: '0' } : { width: '92%', height: '235px', padding: '10px 0 0 0' }} className="desktopHeaderItens showOnMobile">
        {/* {height for all itens: 275px} */}
        <p onClick={() => handleClick('home')} className="smallText" id={pages.home}>Home</p>
        <p onClick={() => handleClick('servicos')} className="smallText" id={pages.servicos}>Serviços</p>
        <p onClick={() => handleClick('sobre')} className="smallText" id={pages.quemSomos}>Quem Somos</p>
        {/* <p onClick={() => handleClick('Blog')} className="smallText" id={pages.Blog}>Blog</p> */}
        <p onClick={() => handleClick('compliance')} className="smallText" id={pages.compliance}>Compliance</p>
        <p onClick={() => handleClick('contato')} className="smallText" id={pages.contato}>Contato</p>
      </div>

      <div className="DesktopHeader">

        <div onClick={() => handleClick('home')} className="pamHeaderLogo">
          <img alt="Logo da Saniteck" src={SaniteckLogo} />
        </div>

        <div style={{ marginLeft: 'auto', marginRight: 'auto' }} className="desktopHeaderItens">
          <p onClick={() => handleClick('home')} className="smallText" id={desktopPages.home}>Home</p>
          <p onClick={() => handleClick('servicos')} className="smallText" id={desktopPages.servicos}>Serviços</p>
          <p onClick={() => handleClick('sobre')} className="smallText" id={desktopPages.quemSomos}>Quem Somos</p>
          {/* <p onClick={() => handleClick('Blog')} className="smallText" id={desktopPages.Blog}>Blog</p> */}
          <p onClick={() => handleClick('compliance')} className="smallText" id={desktopPages.compliance}>Compliance</p>
          <p onClick={() => handleClick('contato')} className="smallText" id={desktopPages.contato}>Contato</p>
        </div>

        <button onClick={openModal} style={{ display: 'none' }} type="button" className="orangeBtn" id="headerBtn">
          <p className="smallText">Quero Saber Mais</p>
        </button>
      </div>
    </div>
  );
}

export default Header;
