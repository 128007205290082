/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import './Privacidade.css';

function PrivacidadePage() {
  return (
    <div>
      <Header />
      <div className="ComplianceTextPage">
        <div className="textPageStart">
          <h1 className="pageNameText">Política de Privacidade e Proteção de Dados</h1>
          <p className="biggerText" style={{ fontWeight: 500 }}>Esta política foi criada considerando as diferentes formas por meio das quais os dados pessoais são tratados pela Saniteck.</p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Objetivos</h2>
          <p className="smallerText">
            A presente Política de Privacidade e Proteção de Dados tem como objetivo explicar como a SANITECK trata e protege os dados pessoais dos Usuários que navegam em nosso site e ainda como esses poderão exercer os seus direitos em relação ao tratamento dos seus dados pessoais.
            <br />
            <br />
            Informamos que o tratamento dos seus dados pessoais será realizado de forma clara e adequada, de acordo com as obrigações e as garantias previstas na legislação aplicável, em especial, a LGPD, que estabelece diretrizes gerais para o Controlador e o Operador. A SANITECK respeita os direitos e liberdades fundamentais das pessoas, entre os quais está o direito à proteção e à privacidade dos dados pessoais.
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Base legal para o tratamento de Dados Pessoais</h2>
          <p className="smallerText">
            A LGPD prevê dez bases ou justificativas legais que legitimam o tratamento de dados pessoais. Utilizaremos os seus dados pessoais em estrito respeito a essas justificativas legais, indicadas na LGPD.
            <br />
            <br />
            A base legal para tratamento dependerá da finalidade de uso dos seus dados pessoais pela SANITECK, entre as quais listamos, de forma não exaustiva:
          </p>
          <ul className="smallerText" style={{ fontWeight: 500, paddingLeft: '24px' }}>
            <li>O cumprimento de uma obrigação legal ou regulatória;</li>
            <li>O nosso legítimo interesse em tratar o dado pessoal, sempre que a finalidade esteja dentro das expectativas do Titular;</li>
            <li>Quando é necessário o seu consentimento para fazê-lo.</li>
          </ul>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Tratamento de Dados Pessoais </h2>
          <p className="smallerText">
            A definição de tratamento de dados pessoais na LGPD é muito ampla, mas envolve todo o manuseio de dados pessoais. Leve em conta que certas atividades de tratamento específicas podem estar sujeitas a um termo de uso ou de privacidade separado e adaptado.
          </p>
          <h3 className="blueText biggerText" style={{ fontWeight: 600 }}>O que é um Dado Pessoal?</h3>
          <p className="smallerText">
            Dado pessoal é toda informação relacionada a uma pessoa natural, que a torna identificada ou identificável. Isto quer dizer que um dado, por si só, poderá identificar uma pessoa, como por exemplo, nome, telefone celular, documentos pessoais, endereço, entre outros. Caso essa informação tenha o potencial de tornar uma pessoa identificável, também é considerada dado pessoal.
            <br />
            <br />
            Lembramos que nem todo dado pessoal é sensível, apenas aqueles assim classificados pela LGPD. Dado pessoal sensível guarda relação com as características da personalidade do indivíduo e suas preferências, tal como dado biométrico, desde que vinculado a uma pessoa natural.
          </p>
          <h3 className="blueText biggerText" style={{ fontWeight: 600 }}>Qual o papel da Saniteck no tratamento dos seus dados pessoais?</h3>
          <p className="smallerText">
            A Saniteck, seus parceiros e fornecedores são responsáveis pelo tratamento de dados pessoais dos Usuários, exceto quando houver alguma particularidade nas condições de determinado Serviço.
            <br />
            <br />
            Sempre que couber a Saniteck a atribuição de Controlador dos seus dados pessoais, tal incumbência respeitará todas as obrigações e responsabilidades, conforme indicado na LGPD, restando-nos a responsabilidade pela gestão e comunicação com você em caso de requisição ou reclamação.
            <br />
            <br />
            Nas poucas hipóteses em que a Saniteck for considerado Operador dos dados pessoais, ao recebermos dados pessoais de um Controlador - que poderá ser o seu contratante, por exemplo - cumpriremos com os requisitos e funções atribuídas pela LGPD, inclusive sobre questões técnicas e operacionais que visem garantir a integridade e segurança dos seus dados, respeitando, também, as definições e critérios indicados pelo Controlador de dados, que será o responsável pela gestão das suas informações, bem como, pelo atendimento de suas requisições e reclamações acerca do tratamento dos dados pessoais.
          </p>
          <h3 className="blueText biggerText" style={{ fontWeight: 600 }}>Quais dados pessoais tratamos?</h3>
          <p className="smallerText">
            Tratamos os dados pessoais adquiridos para o fornecimento dos nossos Serviços. Nesse sentido, limitamos o tratamento aos dados mínimos necessários para atender as suas finalidades. Ainda, por meio da sua interação, podemos coletar dados pessoais que incluem, de forma não exaustiva:
            <br />
            <br />
            <span className="orangeText">I. Os dados cadastrais que você disponibilizou para obtenção de informações de nossos serviços</span>, como por exemplo: nome, sexo, Filiação, Naturalidade, Nacionalidade, Data de Nascimento, Estado Civil, Título de Eleitor, Certificado Militar, CPF, Cédula de Identidade, Endereço físico Residencial e Comercial, E-mail, Números de Telefones, bem como os dados de Formação Acadêmica;
            <br />
            <br />
            <span className="orangeText">II. A biometria utilizada para liberação da entrada nas dependências da Saniteck e ponto eletrônico.</span>
            <br />
            Podemos ainda realizar o tratamento de dados pessoais quando for necessário para proteger nossos serviços, detectar, impedir e resolver fraudes, inclusive para tratar incidentes de segurança, de privacidade ou qualquer outra atividade ilegal, até mesmo como forma de preveni-las.
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Links para sites e aplicativos de terceiros</h2>
          <p className="smallerText">
            Nosso site pode conter links para outros sites que não possuímos ou operamos, inclusive links de anunciantes ou parceiros autorizados a utilizar nossa logomarca. Note que dentro desses sites você estará sujeito a outros Termos de Uso e Políticas de Privacidade.
            <br />
            <br />
            Lembramos que esta Política é válida somente para a Saniteck, portanto, suas regras não se aplicam a sites de terceiros. É importante ressaltar que a existência desses links não implica em qualquer relação de endosso ou de patrocínio entre a Saniteck e esses terceiros, assim, não temos nenhuma responsabilidade com relação a esses, inclusive em relação à coleta e tratamento de dados pessoais e na utilização de cookies realizados por esses terceiros.
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Segurança e confidencialidade no tratamento de seus dados pessoais</h2>
          <p className="smallerText">
            Nosso site pode conter links para outros sites que não possuímos ou operamos, inclusive links de anunciantes ou parceiros autorizados a utilizar nossa logomarca. Note que dentro desses sites você estará sujeito a outros Termos de Uso e Políticas de Privacidade.
            <br />
            <br />
            Lembramos que esta Política é válida somente para a Saniteck, portanto, suas regras não se aplicam a sites de terceiros. É importante ressaltar que a existência desses links não implica em qualquer relação de endosso ou de patrocínio entre a Saniteck e esses terceiros, assim, não temos nenhuma responsabilidade com relação a esses, inclusive em relação à coleta e tratamento de dados pessoais e na utilização de cookies realizados por esses terceiros.
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Período de Armazenamento</h2>
          <p className="smallerText">
            Os dados pessoais serão armazenados pela Saniteck durante o período necessário e conforme as finalidades para as quais foram coletados. Esses dados serão mantidos durante o relacionamento com o Titular e/ou pelo tempo obrigatório para cumprirmos com as obrigações legais, contratuais ou regulatórias.
            <br />
            <br />
            Nos comprometemos a mantê-los armazenados, adotando todas as medidas necessárias e razoáveis para impedir sua alteração, perda e acesso não autorizado, conforme determinação da legislação aplicável e melhores práticas.
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Encarregado de Proteção de Dados</h2>
          <p className="smallerText">
            O Encarregado (DPO) é o responsável pela privacidade e proteção dos dados pessoais tratados pela SANITECK. Suas principais funções são:
          </p>
          <ul className="smallerText" style={{ fontWeight: 500, paddingLeft: '24px' }}>
            <li>Aceitar reclamações e comunicações dos titulares, prestar esclarecimentos e adotar providências;</li>
            <li>Receber comunicações da autoridade nacional e adotar providências;</li>
            <li>Orientar os funcionários e os contratados da entidade a respeito das práticas a serem tomadas em relação à proteção de dados pessoais;</li>
            <li>Executar as demais atribuições determinadas pelo controlador ou estabelecidas em normas complementares.</li>
          </ul>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Exercício de Direito</h2>
          <p className="smallerText">
            Em conformidade com o estabelecido na legislação aplicável, a Saniteck informa que disponibiliza canais de comunicação para que seus Usuários possam confirmar, consultar o tratamento, acessar, atualizar e corrigir seus dados pessoais garantindo o pleno exercício de seus direitos.
            <br />
            <br />
            Informamos que Você tem os seguintes direitos relacionados à proteção e privacidade de seus dados pessoais:
          </p>
          <ul className="smallerText" style={{ fontWeight: 500, paddingLeft: '24px' }}>
            <li>Caso deseje acessar, corrigir ou atualizar os seus dados pessoais, Você poderá fazê-lo a qualquer tempo;</li>
            <li>Você tem o direito de cancelar o envio de comunicados por e-mail marketing e/ou envio de SMS (opt-out);</li>
            <li>Da mesma forma, caso os seus dados pessoais sejam tratados mediante o seu consentimento, você poderá desconsentir quando desejar. Contudo, o não consentimento não afetará a legitimidade do tratamento realizado anteriormente, tampouco prejudicará o tratamento executado com base em outras bases legais.</li>
          </ul>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Como entrar em contato</h2>
          <p className="smallerText">
            <strong>
              <a style={{ color: '#000' }} href="mailto:lgpd@saniteck.com.br" id="anchorNoStyle">
                lgpd@saniteck.com.br
              </a>
            </strong>
          </p>
        </div>

        <div className="textAndHeadingContainer">
          <h2 className="orangeText headingText">Atualizações</h2>
          <p className="smallerText">
            Esta Política de Privacidade e Proteção de Dados poderá ser revisada a qualquer tempo e sem prévio aviso, levando-se em consideração a legislação aplicável e as mudanças organizacionais que ocorram a qualquer momento, a fim de manter sua pertinência e eficácia.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacidadePage;
