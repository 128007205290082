/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactModal from 'react-modal';
import './ModalComponent.css';
import Icon from '../Media/Modal/Icon.svg';

let aaaa = false;

function ModalComponent() {
  const [openModal, setModalOpen] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const thisModal = React.useRef(null);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const modalState = localStorage.getItem('isTheSaniteckWebsiteModalOpen');
      if (modalState === 'true') {
        setModalOpen(true);
        setCount(count + 1);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [openModal, count]);

  function buildForm() {
    if (thisModal.current !== null) {
      const form = thisModal.current.querySelectorAll('form');
      if (form.length > 1) {
        return;
      }
      if (form.length === 0 && aaaa === false) {
        aaaa = true;
        new window.RDStationForms('contato-site-institucional-c9f4c30e9fb7d2d0d3f2', 'UA-258025768-1').createForm();
      }
    }
  }

  buildForm();

  function closeModal() {
    localStorage.removeItem('isTheSaniteckWebsiteModalOpen');
    setModalOpen(false);
    aaaa = false;
  }

  window.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      setModalOpen(false);
    }
  });

  return (
    <ReactModal 
      appElement={document.getElementById('root')}
      shouldCloseOnEsc
      isOpen={openModal}
      className="modal"
      portalClassName="modal"
    >
      <div className="aaaaaaaa1" ref={thisModal}>

        <button style={{ alignSelf: 'flex-end', boxShadow: 'none', filter: 'none' }} type="button" className="whiteBtn buttonWithArrow" onClick={closeModal}>
          <img alt="Fechar Modal" src={Icon} />
        </button>

        <div role="main" id="contato-site-institucional-c9f4c30e9fb7d2d0d3f2" />
      </div>
    </ReactModal>
  );
}

export default ModalComponent;
