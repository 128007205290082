/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useNavigate } from 'react-router';
import './Footer.css';
import SaniteckLogo from '../Media/SaniteckLogo.svg';
// import Setinha from '../Media/Setinha.svg';
import Facebook from '../Media/Footer/Facebook.svg';
import Instagram from '../Media/Footer/Instagram.svg';
import Linkedin from '../Media/Footer/Linkedin.svg';
import phone from '../Media/Footer/Phone.svg';
import zap from '../Media/Footer/zap.svg';

function Footer() {
  const navigation = useNavigate();

  function navigate(params, elementToFind) {
    navigation(params);
    setTimeout(() => {
      console.log('rodou aqui');
      document.getElementById(elementToFind).scrollIntoView({ block: 'center' });
    }, 50);
  }

  function handleWhatsapp() {
    const whatsappElement = document.querySelector('.bricks--floating--button');
    console.log(whatsappElement);
    whatsappElement.click();
  }

  function handleClick(clickedElement, idToFind) {
    window.scrollTo(0, 0);
    switch (clickedElement) {
      case 'servicos':
        navigate('/servicos', idToFind);
        break;
      case 'home':
        navigate('/');
        break;
      case 'Blog':
        window.open('https://blog.saniteck.com.br/', '_self');
        break;
      case 'sobre':
        navigate('/quemSomos');
        break;
      case 'contato':
        navigate('/contato');
        break;
      case 'compliance':
        navigate('/compliance');
        break;
      case 'privacidade':
        navigate('/privacidade');
        break;
      case 'cookies':
        navigate('/cookies');
        break;
      case 'canalDeEtica':
        navigate('/compliance', 'canalDeEtica');
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <div className="faleConoscoDiv">
        <h3>Se interessou? <br className="showOnMobile" /> Fala com a gente!</h3>
        <p>Estamos disponíveis de segunda à sexta, de 7h30 às 17h30, aos sábados de 7h30 às 11h30.</p>
        <div>
          <div onClick={handleWhatsapp} style={{ cursor: 'pointer' }} className="textAndImage">
            <img src={zap} alt="" />
            <p>(92) 98802-3592</p>
          </div>
          <div className="textAndImage">
            <img src={phone} alt="" />
            <p>(92) 3612-1212</p>
          </div>
        </div>
      </div>

      <div className="FooterComponent">
        <div className="FooterNavContainer">
          <div>
            <h4 onClick={() => handleClick('home')} className="smallText footerNav">Home</h4>
          </div>
          <div>
            <h4 onClick={() => handleClick('servicos')} className="smallText footerNav">Serviços</h4>
            <p onClick={() => handleClick('servicos', 'agua')} className="smallText footerNav">Higienização de Reservatórios</p>
            <p onClick={() => handleClick('servicos', 'poco')} className="smallText footerNav">Manutenção de Poço</p>
            <p onClick={() => handleClick('servicos', 'pragas')} className="smallText footerNav">Controle de Pragas Urbanas</p>
            <p onClick={() => handleClick('servicos', 'efluentes')} className="smallText footerNav">Manutenção de ETE</p>
            <p onClick={() => handleClick('servicos', 'sanitizacao')} className="smallText footerNav">Sanitização</p>
            <p onClick={() => handleClick('servicos', 'fossa')} className="smallText footerNav">Esgotamento de Fossa</p>
          </div>
          <div>
            <h4 onClick={() => handleClick('sobre')} className="smallText footerNav">Quem Somos</h4>
            <p onClick={() => handleClick('sobre')} className="smallText footerNav">Missão e Valores</p>
          </div>
          {/* <div>
            <h4 onClick={() => handleClick('Blog')} className="smallText footerNav">Blog</h4>
          </div> */}
          <div>
            <h4 onClick={() => handleClick('compliance')} className="smallText footerNav">Compliance</h4>
            <p onClick={() => handleClick('compliance')} className="smallText footerNav">Documentação</p>
            <p onClick={() => handleClick('canalDeEtica')} className="smallText footerNav">Canal de Ética</p>
          </div>
          <div>
            <h4 onClick={() => handleClick('contato')} className="smallText footerNav">Contato</h4>
            <p onClick={() => handleClick('contato')} className="smallText footerNav">Fale conosco</p>
          </div>
        </div>
        <div className="socialsContainer hideOnMobile">
          <img onClick={() => window.open('https://www.instagram.com/saniteckoficial/')} src={Instagram} alt="Instagram" />
          <img onClick={() => window.open('https://www.facebook.com/saniteckoficial/')} src={Facebook} alt="Facebook" />
          <img onClick={() => window.open('https://www.linkedin.com/company/saniteck-oficial/')} src={Linkedin} alt="Linkedin" />
        </div>

        <div className="footerDecorationDiv" />

        <div className="footerLastContainer standardContainer">
          <div>
            <img src={SaniteckLogo} alt="Saniteck!" id="footerLogo" />
            <p className="hideOnMobile">Copyright ©2023. Saniteck.</p>
          </div>
        
          <div className="socialsContainer showOnMobile">
            <img onClick={() => window.open('https://www.instagram.com/saniteckoficial/')} src={Instagram} alt="Instagram" />
            <img onClick={() => window.open('https://www.facebook.com/saniteckoficial/')} src={Facebook} alt="Facebook" />
            <img onClick={() => window.open('https://www.linkedin.com/company/saniteck-oficial/')} src={Linkedin} alt="Linkedin" />
          </div>

          <p className="showOnMobile">Copyright ©2023. Saniteck.</p>

          <div className="linksContainer">
            <a onClick={() => handleClick('privacidade')}>Política de Privacidade e Proteção de Dados</a>
            <a onClick={() => handleClick('cookies')}>Política de Cookies</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
