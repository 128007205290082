import React from 'react';
import './Sobre.css';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import aboutFirstImage from '../../Media/Sobre/aboutFirstImage.webp';
import certificationsImage from '../../Media/Sobre/certificationsImage.svg';
import missao from '../../Media/Sobre/missao.svg';
import visao from '../../Media/Sobre/visao.svg';
import valores from '../../Media/Sobre/valores.svg';
import anos from '../../Media/Sobre/anos.svg';
import time from '../../Media/Sobre/time.svg';
import atendimento from '../../Media/Sobre/atendimento.svg';
import melhores from '../../Media/Sobre/melhores.svg';

function SobrePage() {
  return (
    <div className="SobrePage">
      <Header />
      <div className="SobreMain">
        <div className="standardContainer aboutFirstContainer">
          <div className="aboutFirstTextContainer">
            <h2 style={{ fontWeight: 700 }} className="headingText blueText">Quem é a Saniteck?</h2>
            <h1 className="aboutHeaderText orangeText">SOMOS SOLUÇÕES SANITÁRIAS</h1>
            <p style={{ fontWeight: 500 }} className="biggerText">Focados em tratamento de efluentes e controle de pragas urbanas.</p>
          </div>
          <div className="imageContainer">
            <img src={aboutFirstImage} alt="Nossa equipe!" />
          </div>
        </div>

        <div style={{ backgroundColor: '#FAF9F6', textAlign: 'center', paddingTop: '100px' }}>
          <h2 style={{ marginBottom: '16px' }} className="headingText blueText">A prioridade é cuidar da sua saúde</h2>
          <p className="smallerText">De forma susténtavel e com excelência</p>
          <div className="standardContainer aboutSecondContainer">
            <div>
              <img src={visao} alt="" />
              <h3 className="orangeText">Nossa visão</h3>
              <p className="smallerText">Ser a melhor empresa do segmento de saneamento e controle de pragas na região norte através de investimentos em inovação, expansão e desenvolvimento de talentos.</p>
            </div>

            <div>
              <img src={missao} alt="" />
              <h3 className="orangeText">Nossa missão</h3>
              <p className="smallerText">Garantir um ambiente seguro e saudável para sua família e empresa através de um serviço eficiente e confiável, respeitando o meio ambiente e a legislação.</p>
            </div>

          </div>
          <div className="ourValuesDiv">
            <img src={valores} alt="" />
            <h3 className="orangeText">Nossos valores</h3>
            <div className="valuesDiv">
              <div className="valueDiv">
                <h4 className="smallerText blueText" style={{ fontWeight: 700 }}>Saúde e segurança em primeiro lugar</h4>
                <p className="smallerText">Damos sempre a melhor solução.</p>
              </div>
              <div className="valueDiv">
                <h4 className="smallerText blueText" style={{ fontWeight: 700 }}>Mão na massa</h4>
                <p className="smallerText">Solucionamos o problema do cliente, vamos lá e fazemos.</p>
              </div>
              <div className="valueDiv">
                <h4 className="smallerText blueText" style={{ fontWeight: 700 }}>Inovação</h4>
                <p className="smallerText">Tendências e tecnologia aplicadas.</p>
              </div>
              <div className="valueDiv">
                <h4 className="smallerText blueText" style={{ fontWeight: 700 }}>Sustentabilidade</h4>
                <p className="smallerText">Primamos pela responsabilidade social e ambiental.</p>
              </div>
              <div className="valueDiv">
                <h4 className="smallerText blueText" style={{ fontWeight: 700 }}>Igualdade e oportunidade</h4>
                <p className="smallerText">Respeito e oportunidade para todos.</p>
              </div>
              <div className="valueDiv">
                <h4 className="smallerText blueText" style={{ fontWeight: 700 }}>Superamos as expectativas</h4>
                <p className="smallerText">Qualidade de processos, pessoas e produtos.</p>
              </div>
            </div>
          </div>
        </div>

        <h2 style={{ marginTop: '100px' }} className="headingText">Garantia de cumprir com nosso dever</h2>

        <div className="standardContainer aboutThirdContainer">
          <div className="dutiesContainer">
            <div style={{ marginBottom: '60px' }} className="textAndImage">
              <img src={anos} alt="" />
              <p>Mais de 42 anos de atuação no mercado.</p>
            </div>

            <div className="textAndImage">
              <img src={time} alt="" />
              <p>Time técnico mais capacitado da região.</p>
            </div>
            <p style={{ marginBottom: '44px' }}>Composto por geólogo, biólogo, químico, engenheiro ambiental e engenheiro sanitarista.</p>

            <div className="textAndImage">
              <img src={atendimento} alt="" />
              <p>Rápido atendimento e alta disponibilidade.</p>
            </div>
            <p>Disponibilidade de horário para atender o cliente de acordo com sua necessidade.</p>
          </div>

          <div className="theBestsContainer">
            <div className="textAndImage">
              <img src={melhores} alt="" />
              <p>Estamos com os melhores</p>
            </div>
            <div className="certificationsContainer imageContainer">
              <img alt="Nossos Certificados" src={certificationsImage} />
            </div>
          </div>
        </div>

        <h3 style={{ textAlign: 'center', margin: '0px 60px', fontWeight: 600 }} className="biggerText blueText">Licenciamento em todos os orgãos ambientais</h3>
        <div className="orgaosContainer" />

      </div>
      <Footer />
    </div>
  );
}

export default SobrePage;
